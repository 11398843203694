import axios from 'axios'

export default async function compras() {
  return 0
}

export async function fetchCompras({ commit }) {
  try {
    const { data } = await axios.get('compras')
    commit('setCompras', data)
  } catch (e) {
    commit('compraError', e.response.data)
  }
}

export async function pdfCompra({ commit }, pdf) {
  try {
    const { id, tipo } = pdf
    const { baseURL } = axios.defaults
    const url = `${baseURL}/compras/pdf/${id}/${tipo}`
    if (tipo === 'download') {
      window.location.href = url
    } else if (tipo === 'view') {
      window.open(url, '_blank')
    }
  } catch (e) {
    commit('compraError', e.response.data)
  }
}

export async function addCompras({ commit, dispatch }, compra) {
  try {
    commit('compraErrorNull')
    await axios({
      method: 'POST',
      url: 'compras',
      data: {
        fecha: compra.fecha,
        referencia: compra.referencia,
        valorTotal: compra.valorTotal,
        solicitadoPor: compra.solicitadoPor,
        numCotizacion: compra.numCotizacion,
        tiempoGarantia: compra.tiempoGarantia,
        observaciones: compra.observaciones,
        lugarEntrega: compra.lugarEntrega,
        condicionPago: compra.condicionPago,
        fechaEntrega: compra.fecha,
        estado: 'Pendiente',
        idProveedor: compra.idProveedor,
        idUsuario: compra.idUsuario,
        comprasProductos: compra.comprasProductos,
      },
    })
    dispatch('fetchCompras')
  } catch (e) {
    commit('compraError', e.response.data)
  }
}

export async function updateCompras({ commit, dispatch }, compra) {
  try {
    commit('compraErrorNull')
    await axios({
      method: 'PUT',
      url: `/compras/${compra.id}`,
      data: {
        fecha: compra.fecha,
        referencia: compra.referencia,
        valorTotal: compra.valorTotal,
        solicitadoPor: compra.solicitadoPor,
        numCotizacion: compra.numCotizacion,
        tiempoGarantia: compra.tiempoGarantia,
        observaciones: compra.observaciones,
        lugarEntrega: compra.lugarEntrega,
        condicionPago: compra.condicionPago,
        fechaEntrega: compra.fechaEntrega,
        idProveedor: compra.idProveedor,
        idUsuarioEdit: compra.idUsuario,
        comprasProductos: compra.comprasProductos,
      },
    })
    dispatch('fetchCompras')
  } catch (e) {
    commit('compraError', e.response.data)
  }
}

export async function updateCompraEstado({ commit, dispatch }, compra) {
  let est = ''
  try {
    commit('compraErrorNull')
    if (compra.estado === 'Pendiente') {
      est = 'Aprobado'
    }
    await axios({
      method: 'PUT',
      url: `/compras/${compra.id}`,
      data: {
        cambioEstado: true,
        fecha: compra.fecha,
        referencia: compra.referencia,
        valorTotal: compra.valorTotal,
        solicitadoPor: compra.solicitadoPor,
        numCotizacion: compra.numCotizacion,
        tiempoGarantia: compra.tiempoGarantia,
        observaciones: compra.observaciones,
        lugarEntrega: compra.lugarEntrega,
        condicionPago: compra.condicionPago,
        fechaEntrega: compra.fechaEntrega,
        estado: est,
        idProveedor: compra.idProveedor,
        comprasProductos: compra.comprasProductos,
      },
    })
    dispatch('fetchCompras')
  } catch (e) {
    commit('compraError', e.response.data)
  }
}

export async function removeCompras({ commit, dispatch }, { id, opcionStock = null }) {
  try {
    commit('compraErrorNull')
    await axios({
      method: 'DELETE',
      url: `/compras/${id}/${opcionStock}`,
    })
    dispatch('fetchCompras')
  } catch (e) {
    commit('compraError', e.response.data)
  }
}
