import axios from 'axios'

export default async function alarmas() {
  return 0
}

export async function fetchAlarmas({ commit }) {
  try {
    const { data } = await axios.get('alarmas')
    commit('setAlarmas', data[0])
  } catch (e) {
    commit('alarmaError', e.response.data)
  }
}

export async function updateAlarmas({ commit }, alarma) {
  try {
    commit('alarmaErrorNull')
    await axios({
      method: 'PUT',
      url: `/alarmas/${alarma.id}`,
      data: {
        tramoDesdeVerde: alarma.tramoDesdeVerde,
        tramoDesdeAmarillo: alarma.tramoDesdeAmarillo,
        tramoDesdeRojo: alarma.tramoDesdeRojo,
      },
    })
  } catch (e) {
    commit('alarmaError', e.response.data)
  }
}
