import axios from 'axios'

export default async function salidas() {
  return 0
}

export async function fetchSalidas({ commit }) {
  try {
    const { data } = await axios.get('salidas')
    commit('setSalidas', data)
  } catch (e) {
    commit('salidaError', e.response.data)
  }
}

export async function addSalidas({ commit, dispatch }, salida) {
  try {
    commit('salidaErrorNull')
    await axios({
      method: 'POST',
      url: 'salidas',
      data: {
        correlativo: salida.correlativo,
        fecha: salida.fecha,
        referencia: salida.referencia,
        valorTotal: salida.valorTotal,
        idCliente: salida.idCliente,
        idUsuario: salida.idUsuario,
        tramoDesdeAmarillo: salida.tramoDesdeAmarillo,
        tramoDesdeRojo: salida.tramoDesdeRojo,
        idCategoria: salida.idCategoria,
        idUbicacion: salida.idUbicacion,
        salidasProductos: salida.salidasProductos,
      },
    })
    dispatch('fetchSalidas')
  } catch (e) {
    commit('salidaError', e.response.data)
  }
}

export async function updateSalidas({ commit, dispatch }, salida) {
  try {
    commit('salidaErrorNull')
    await axios({
      method: 'PUT',
      url: `/salidas/${salida.id}`,
      data: {
        correlativo: salida.correlativo,
        fecha: salida.fecha,
        referencia: salida.referencia,
        valorTotal: salida.valorTotal,
        idCliente: salida.idCliente,
        idUsuario: salida.idUsuario,
        tramoDesdeAmarillo: salida.tramoDesdeAmarillo,
        tramoDesdeRojo: salida.tramoDesdeRojo,
        idCategoria: salida.idCategoria,
        idUbicacion: salida.idUbicacion,
        salidaProductos: salida.salidasProductos,
      },
    })
    dispatch('fetchSalidas')
  } catch (e) {
    commit('salidaError', e.response.data)
  }
}

// export async function updateSalidaEstado({ commit, dispatch }, salida) {
//   let est = ''
//   try {
//     commit('salidaErrorNull')
//     if (salida.estado === 'Agotado') {
//       est = 'Activo'
//     } else if (salida.estado === 'Activo') {
//       est = 'Inactivo'
//     }
//     await axios({
//       method: 'PUT',
//       url: `/salidas/${salida.id}`,
//       data: {
//         rut: salida.rut,
//         direccion: salida.direccion,
//         razonSocial: salida.razonSocial,
//         nombre: salida.nombre,
//         telefono: salida.telefono,
//         correo: salida.correo,
//         estado: est,
//       },
//     })
//     // actualizamos lista de salidas
//     dispatch('fetchSalidas')
//   } catch (e) {
//     commit('salidaError', e.response.data)
//   } finally {
//   }
// }

export async function removeSalidas({ commit, dispatch }, { id, opcionStock }) {
  try {
    commit('salidaErrorNull')
    await axios({
      method: 'DELETE',
      url: `/salidas/${id}/${opcionStock}`,
    })
    // actualizamos lista de salidas
    dispatch('fetchSalidas')
  } catch (e) {
    commit('salidaError', e.response.data)
  }
}
