import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import usuarios from './usuarios'
import permissions from './permissions'
import categorias from './categorias'
import ubicaciones from './ubicaciones'
import clientes from './clientes'
import proveedores from './proveedores'
import alarmas from './alarmas'
import productos from './productos'
import salidas from './salidas'
import compras from './compras'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    usuarios,
    permissions,
    categorias,
    ubicaciones,
    clientes,
    proveedores,
    alarmas,
    productos,
    salidas,
    compras,
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
})
