export function setSalidas(state, salidas) {
  state.salidas = salidas
}

export function setSalida(state, salida) {
  state.selectedSalida = salida
}

export function updateSalidaEstado(state, payload) {
  const salida = state.salidas.find(u => u.id === payload.id)
  if (salida) {
    state.done = !salida.done
  }
}

export function salidaError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.salidas = []
}

export function salidaErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
