export default {
  productos: [],
  productosHistorial: [],
  productosSalidas: [],
  productosCompras: [],
  selectedProducto: null,
  error: false,
  errorMessage: [],
  unidades: [
    { value: 'UD', text: 'UD' },
    { value: 'MM', text: 'MM' },
    { value: 'CM', text: 'CM' },
    { value: 'M', text: 'M' },
    { value: 'L', text: 'L' },
  ],
}
