export function setUbicaciones(state, ubicaciones) {
  state.ubicaciones = ubicaciones
}

export function setUbicacionesActivas(state, ubicaciones) {
  state.ubicacionesActivas = ubicaciones
}

export function setUbicacion(state, ubicacion) {
  state.selectedUbicacion = ubicacion
}

export function updateUbicacionEstado(state, payload) {
  const ubicacion = state.ubicaciones.find(u => u.id === payload.id)
  if (ubicacion) {
    state.done = !ubicacion.done
  }
}

export function ubicacionError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.ubicaciones = []
}

export function ubicacionErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
