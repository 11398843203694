import axios from 'axios'

export default async function user() {
  return 0
}

export async function fetchUsuarios({ commit }) {
  try {
    const { data } = await axios.get('usuarios')
    commit('setUsuarios', data)
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function addUsuarios({ commit, dispatch }, usuario) {
  try {
    commit('usuarioErrorNull')
    const imagen = btoa(usuario.imagen)
    await axios({
      method: 'POST',
      url: 'usuarios',
      data: {
        nombre: usuario.nombre,
        telefono: usuario.telefono,
        telefonoInterno: usuario.telefonoInterno,
        correo: usuario.correo,
        password: usuario.password,
        imagen,
        estado: usuario.estado,
        tipoUsuario: usuario.tipoUsuario,
        permissionsUsuario: usuario.permissionsUsuario,
      },
    })
    dispatch('fetchUsuarios')
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function updateUsuarios({ commit, dispatch }, usuario) {
  try {
    commit('usuarioErrorNull')
    const imagen = btoa(usuario.imagen)
    await axios({
      method: 'PUT',
      url: `/usuarios/${usuario.id}`,
      data: {
        // id: usuario.id,
        nombre: usuario.nombre,
        telefono: usuario.telefono,
        telefonoInterno: usuario.telefonoInterno,
        correo: usuario.correo,
        password: usuario.password,
        imagen,
        estado: usuario.estado,
        tipoUsuario: usuario.tipoUsuario,
        permissionsUsuario: usuario.permissionsUsuario,
      },
    })
    dispatch('fetchUsuarios')
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function updateUsuarioEstado({ commit, dispatch }, usuario) {
  let est = ''
  try {
    commit('usuarioErrorNull')
    if (usuario.estado === 'Inactivo') {
      est = 'Activo'
    } else if (usuario.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/usuarios/estado/${usuario.id}`,
      data: {
        estado: est,
      },
    })
    dispatch('fetchUsuarios')
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}

export async function updatePasswordUsuario({ commit, dispatch }, usuario) {
  let message
  try {
    await axios({
      method: 'PUT',
      url: `/usuarios/password/${usuario.id}`,
      data: {
        password: usuario.password,
      },
    })
    dispatch('fetchUsuarios')
    message = true
  } catch (e) {
    commit('usuarioError', e.response.data)
    message = false
  }
  return message
}

export async function removeUsuarios({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/usuarios/${id}`,
    })
    // actualizamos lista de usuarios
    dispatch('fetchUsuarios')
  } catch (e) {
    commit('usuarioError', e.response.data)
  }
}
