export default function cliente() {
  return 0
}

export function getClientes(state) {
  return state.clientes
}

export function getClientesActivos(state) {
  return state.clientesActivos
}
