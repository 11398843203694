import axios from 'axios'

export default async function clientesDefault() {
  return 0
}

export async function fetchClientes({ commit }) {
  try {
    const { data } = await axios.get('clientes')
    commit('setClientes', data)
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}

export async function fetchClientesActivos({ commit }) {
  try {
    const { data } = await axios.get('clientes/activos')
    commit('setClientesActivos', data)
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}

export async function addClientes({ commit, dispatch }, cliente) {
  try {
    commit('clienteErrorNull')
    await axios({
      method: 'POST',
      url: 'clientes',
      data: {
        rut: cliente.rut,
        direccion: cliente.direccion,
        razonSocial: cliente.razonSocial,
        nombre: cliente.nombre,
        telefono: cliente.telefono,
        correo: cliente.correo,
        estado: 'Activo',
      },
    })
    dispatch('fetchClientes')
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}

export async function updateClientes({ commit, dispatch }, cliente) {
  try {
    commit('clienteErrorNull')
    await axios({
      method: 'PUT',
      url: `/clientes/${cliente.id}`,
      data: {
        rut: cliente.rut,
        direccion: cliente.direccion,
        razonSocial: cliente.razonSocial,
        nombre: cliente.nombre,
        telefono: cliente.telefono,
        correo: cliente.correo,
        estado: 'Activo',
      },
    })
    dispatch('fetchClientes')
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}

export async function updateProveedorEstado({ commit, dispatch }, cliente) {
  let est = ''
  try {
    commit('clienteErrorNull')
    if (cliente.estado === 'Inactivo') {
      est = 'Activo'
    } else if (cliente.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/clientes/${cliente.id}`,
      data: {
        rut: cliente.rut,
        direccion: cliente.direccion,
        razonSocial: cliente.razonSocial,
        nombre: cliente.nombre,
        telefono: cliente.telefono,
        correo: cliente.correo,
        estado: est,
      },
    })
    // actualizamos lista de clientes
    dispatch('fetchClientes')
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}

export async function removeClientes({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/clientes/${id}`,
    })
    // actualizamos lista de clientes
    dispatch('fetchClientes')
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}

export async function importClientes({ commit }, clientes) {
  try {
    await axios({
      method: 'POST',
      url: 'clientes/import',
      data: {
        clientes,
      },
    })
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}
