export function setProductos(state, productos) {
  state.productos = productos
}

export function setProductosHistorial(state, productosHistorial) {
  state.productosHistorial = productosHistorial
}

export function setProductosSalidas(state, productosSalidas) {
  state.productosSalidas = productosSalidas
}

export function setProductosCompras(state, productosCompras) {
  state.productosCompras = productosCompras
}

export function setProducto(state, producto) {
  state.selectedProducto = producto
}

export function updateProductoEstado(state, payload) {
  const producto = state.productos.find(u => u.id === payload.id)
  if (producto) {
    state.done = !producto.done
  }
}

export function productoError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.productos = []
}

export function productoErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
