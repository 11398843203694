import axios from 'axios'

export default async function categorias() {
  return 0
}

export async function fetchCategorias({ commit }) {
  try {
    const { data } = await axios.get('categorias')
    commit('setCategorias', data)
  } catch (e) {
    commit('categoriaError', e.response.data)
  }
}

export async function fetchCategoriasActivas({ commit }) {
  try {
    const { data } = await axios.get('categorias/activos')
    commit('setCategoriasActivas', data)
  } catch (e) {
    commit('categoriaError', e.response.data)
  }
}

export async function addCategorias({ commit, dispatch }, categoria) {
  try {
    commit('categoriaErrorNull')
    await axios({
      method: 'POST',
      url: 'categorias',
      data: {
        nombre: categoria.nombre,
        estado: categoria.estado,
      },
    })
    dispatch('fetchCategorias')
    dispatch('fetchCategoriasActivas')
  } catch (e) {
    commit('categoriaError', e.response.data)
  }
}

export async function updateCategorias({ commit, dispatch }, categoria) {
  try {
    commit('categoriaErrorNull')
    await axios({
      method: 'PUT',
      url: `/categorias/${categoria.id}`,
      data: {
        nombre: categoria.nombre,
        estado: categoria.estado,
      },
    })
    dispatch('fetchCategorias')
    dispatch('fetchCategoriasActivas')
  } catch (e) {
    commit('categoriaError', e.response.data)
  }
}

export async function updateCategoriaEstado({ commit, dispatch }, categoria) {
  let est = ''
  try {
    commit('categoriaErrorNull')
    if (categoria.estado === 'Inactivo') {
      est = 'Activo'
    } else if (categoria.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/categorias/${categoria.id}`,
      data: {
        nombre: categoria.nombre,
        estado: est,
      },
    })
    dispatch('fetchCategorias')
    dispatch('fetchCategoriasActivas')
  } catch (e) {
    commit('categoriaError', e.response.data)
  }
}

export async function removeCategorias({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/categorias/${id}`,
    })
    dispatch('fetchCategorias')
    dispatch('fetchCategoriasActivas')
  } catch (e) {
    commit('categoriaError', e.response.data)
  }
}
