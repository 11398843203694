export function setProveedores(state, proveedores) {
  state.proveedores = proveedores
}

export function setProveedoresActivos(state, proveedoresActivos) {
  state.proveedoresActivos = proveedoresActivos
}

export function setProveedor(state, proveedor) {
  state.selectedProveedor = proveedor
}

export function updateProveedorEstado(state, payload) {
  const proveedor = state.proveedores.find(u => u.id === payload.id)
  if (proveedor) {
    state.done = !proveedor.done
  }
}

export function proveedorError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.proveedores = []
}

export function proveedorErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
