import axios from 'axios'

export default async function productosTest() {
  return 0
}

export async function fetchProductos({ commit }) {
  try {
    const { data } = await axios.get('productos')
    commit('setProductos', data)
  } catch (e) {
    commit('productoError', e.response.data)
  }
}

export async function fetchProductosHistorial({ commit }, idProducto) {
  try {
    const url = `/productos/historial/${idProducto}`
    const { data } = await axios.get(url)
    commit('setProductosHistorial', data)
  } catch (e) {
    commit('ProductosHistorialError', e.response.data)
  }
}

export async function fetchProductosSalidas({ commit }, idProducto) {
  try {
    const url = `/productos/salidas/${idProducto}`
    const { data } = await axios.get(url)
    commit('setProductosSalidas', data)
  } catch (e) {
    commit('productoSalidaError', e.response.data)
  }
}

export async function fetchProductosCompras({ commit }, idProducto) {
  try {
    const url = `/productos/compras/${idProducto}`
    const { data } = await axios.get(url)
    commit('setProductosCompras', data)
  } catch (e) {
    commit('productoCompraError', e.response.data)
  }
}

export async function addProductos({ commit, dispatch }, producto) {
  try {
    commit('productoErrorNull')
    const config = {
      headers: {
        header1: 'Content-type: image/png/jpg/jpeg',
      },
    }
    const imagen = btoa(producto.imagen)
    await axios({
      method: 'post',
      url: 'productos',
      config,
      data: {
        codigo: producto.codigo,
        nombre: producto.nombre,
        descripcion: producto.descripcion,
        imagen,
        stock: Number(producto.stock),
        unidad: producto.unidad,
        tramoDesdeVerde: producto.tramoDesdeVerde,
        tramoDesdeAmarillo: producto.tramoDesdeAmarillo,
        tramoDesdeRojo: producto.tramoDesdeRojo,
        idCategoria: producto.idCategoria,
        idUbicacion: producto.idUbicacion,
        preciosCantidad: producto.preciosCantidad,
      },
    })
    dispatch('fetchProductos')
  } catch (e) {
    commit('productoError', e.response.data)
  }
}

export async function updateProductos({ commit, dispatch }, producto) {
  try {
    commit('productoErrorNull')
    const imagen = btoa(producto.imagen)
    await axios({
      method: 'PUT',
      url: `/productos/${producto.id}`,
      data: {
        codigo: producto.codigo,
        nombre: producto.nombre,
        descripcion: producto.descripcion,
        imagen,
        stock: Number(producto.stock),
        unidad: producto.unidad,
        tramoDesdeVerde: producto.tramoDesdeVerde,
        tramoDesdeAmarillo: producto.tramoDesdeAmarillo,
        tramoDesdeRojo: producto.tramoDesdeRojo,
        idCategoria: producto.idCategoria,
        idUbicacion: producto.idUbicacion,
        preciosCantidad: producto.preciosCantidad,
      },
    })
    dispatch('fetchProductos')
  } catch (e) {
    commit('productoError', e.response.data)
  }
}

export async function removeProductos({ commit, dispatch }, id) {
  try {
    commit('productoErrorNull')
    await axios({
      method: 'DELETE',
      url: `/productos/${id}`,
    })
    // actualizamos lista de productos
    dispatch('fetchProductos')
  } catch (e) {
    commit('productoError', e.response.data)
  }
}

export async function importProductos({ commit }, data) {
  try {
    await axios({
      method: 'POST',
      url: 'productos/import',
      data: {
        data,
      },
    })
  } catch (e) {
    commit('productoError', e.response.data)
  }
}
