import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        return next()
      },
      meta: {
        subject: 'home',
        action: 'read',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        return next()
      },
      meta: {
        subject: 'home',
        action: 'read',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: () => import('@/views/usuarios/Usuarios.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'usuarios',
        action: 'read',
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios/crear',
      name: 'usuarios-create',
      component: () => import('@/views/usuarios/UsuariosCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'usuarios',
        action: 'create',
        pageTitle: 'Crear Usuario',
        breadcrumb: [
          {
            text: 'Usuarios',
            to: '/usuarios',
          },
          {
            text: 'Crear Usuario',
            active: true,
          },
        ],
      },
    },
    {
      path: '/usuarios/:id/editar',
      name: 'usuarios-update',
      component: () => import('@/views/usuarios/UsuariosUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'usuarios',
        action: 'update',
        pageTitle: 'Editar Usuario',
        breadcrumb: [
          {
            text: 'Usuarios',
            to: '/usuarios',
          },
          {
            text: 'Editar Usuario',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categorias',
      name: 'categorias',
      component: () => import('@/views/categorias/Categorias.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'categorias',
        action: 'read',
        pageTitle: 'Categorías',
        breadcrumb: [
          {
            text: 'Categorías',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categorias/crear',
      name: 'categorias-create',
      component: () => import('@/views/categorias/CategoriasCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'categorias',
        action: 'create',
        pageTitle: 'Crear Categoría',
        breadcrumb: [
          {
            text: 'Categorías',
            to: '/categorias',
          },
          {
            text: 'Crear Categoría',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categorias/:id/editar',
      name: 'categorias-update',
      component: () => import('@/views/categorias/CategoriasUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'categorias',
        action: 'update',
        pageTitle: 'Editar Categoría',
        breadcrumb: [
          {
            text: 'Categorías',
            to: '/categorias',
          },
          {
            text: 'Editar Categoría',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ubicaciones',
      name: 'ubicaciones',
      component: () => import('@/views/ubicaciones/Ubicaciones.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'ubicaciones',
        action: 'read',
        pageTitle: 'Ubicaciones',
        breadcrumb: [
          {
            text: 'Ubicaciones',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ubicaciones/crear',
      name: 'ubicaciones-create',
      component: () => import('@/views/ubicaciones/UbicacionesCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'ubicaciones',
        action: 'create',
        pageTitle: 'Crear Ubicación',
        breadcrumb: [
          {
            text: 'Ubicaciones',
            to: '/ubicaciones',
          },
          {
            text: 'Crear Ubicación',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ubicaciones/:id/editar',
      name: 'ubicaciones-update',
      component: () => import('@/views/ubicaciones/UbicacionesUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'ubicaciones',
        action: 'update',
        pageTitle: 'Editar Ubicación',
        breadcrumb: [
          {
            text: 'Ubicaciones',
            to: '/ubicaciones',
          },
          {
            text: 'Editar Ubicación',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clientes',
      name: 'clientes',
      component: () => import('@/views/clientes/Clientes.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'clientes',
        action: 'read',
        pageTitle: 'Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clientes/crear',
      name: 'clientes-create',
      component: () => import('@/views/clientes/ClientesCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'clientes',
        action: 'create',
        pageTitle: 'Crear Cliente',
        breadcrumb: [
          {
            text: 'Clientes',
            to: '/clientes',
          },
          {
            text: 'Crear Cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clientes/:id/editar',
      name: 'clientes-update',
      component: () => import('@/views/clientes/ClientesUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'clientes',
        action: 'update',
        pageTitle: 'Editar Cliente',
        breadcrumb: [
          {
            text: 'Clientes',
            to: '/clientes',
          },
          {
            text: 'Editar Cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clientes/:id/crear',
      name: 'clientes-clone',
      component: () => import('@/views/clientes/ClientesClone.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'clientes',
        action: 'create',
        pageTitle: 'Crear Cliente',
        breadcrumb: [
          {
            text: 'Clientes',
            to: '/clientes',
          },
          {
            text: 'Crear Cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/proveedores',
      name: 'proveedores',
      component: () => import('@/views/proveedores/Proveedores.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'proveedores',
        action: 'read',
        pageTitle: 'Proveedores',
        breadcrumb: [
          {
            text: 'Proveedores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/proveedores/crear',
      name: 'proveedores-create',
      component: () => import('@/views/proveedores/ProveedoresCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'proveedores',
        action: 'create',
        pageTitle: 'Crear Proveedor',
        breadcrumb: [
          {
            text: 'Proveedores',
            to: '/proveedores',
          },
          {
            text: 'Crear Proveedor',
            active: true,
          },
        ],
      },
    },
    {
      path: '/proveedores/:id/editar',
      name: 'proveedores-update',
      component: () => import('@/views/proveedores/ProveedoresUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'proveedores',
        action: 'update',
        pageTitle: 'Editar Proveedor',
        breadcrumb: [
          {
            text: 'Proveedores',
            to: '/proveedores',
          },
          {
            text: 'Editar Proveedor',
            active: true,
          },
        ],
      },
    },
    {
      path: '/proveedores/:id/crear',
      name: 'proveedores-clone',
      component: () => import('@/views/proveedores/ProveedoresClone.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'proveedores',
        action: 'create',
        pageTitle: 'Crear Cliente',
        breadcrumb: [
          {
            text: 'Proveedores',
            to: '/proveedores',
          },
          {
            text: 'Crear Cliente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productos',
      name: 'productos',
      component: () => import('@/views/productos/Productos.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'productos',
        action: 'read',
        pageTitle: 'Productos',
        breadcrumb: [
          {
            text: 'Productos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productos/:id/crear',
      name: 'productos-clone',
      component: () => import('@/views/productos/ProductosClone.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'productos',
        action: 'create',
        pageTitle: 'Crear Producto',
        breadcrumb: [
          {
            text: 'Productos',
            to: '/productos',
          },
          {
            text: 'Crear Producto',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productos/crear',
      name: 'productos-create',
      component: () => import('@/views/productos/ProductosCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'productos',
        action: 'create',
        pageTitle: 'Crear Producto',
        breadcrumb: [
          {
            text: 'Productos',
            to: '/productos',
          },
          {
            text: 'Crear Producto',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productos/:id/editar',
      name: 'productos-update',
      component: () => import('@/views/productos/ProductosUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'productos',
        action: 'update',
        pageTitle: 'Editar Producto',
        breadcrumb: [
          {
            text: 'Productos',
            to: '/productos',
          },
          {
            text: 'Editar Producto',
            active: true,
          },
        ],
      },
    },
    {
      path: '/salidas',
      name: 'salidas',
      component: () => import('@/views/salidas/Salidas.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'salidas',
        action: 'read',
        pageTitle: 'Salidas',
        breadcrumb: [
          {
            text: 'Salidas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/salidas/crear',
      name: 'salidas-create',
      component: () => import('@/views/salidas/SalidasCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'salidas',
        action: 'create',
        pageTitle: 'Crear Salida',
        breadcrumb: [
          {
            text: 'Salidas',
            to: '/salidas',
          },
          {
            text: 'Crear Salida',
            active: true,
          },
        ],
      },
    },
    {
      path: '/salidas/:id/editar',
      name: 'salidas-update',
      component: () => import('@/views/salidas/SalidasUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'salidas',
        action: 'update',
        pageTitle: 'Editar Salida',
        breadcrumb: [
          {
            text: 'Salidas',
            to: '/salidas',
          },
          {
            text: 'Editar Salida',
            active: true,
          },
        ],
      },
    },
    {
      path: '/compras',
      name: 'compras',
      component: () => import('@/views/compras/Compras.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'compras',
        action: 'read',
        pageTitle: 'OC',
        breadcrumb: [
          {
            text: 'OC',
            active: true,
          },
        ],
      },
    },
    {
      path: '/compras/crear',
      name: 'compras-create',
      component: () => import('@/views/compras/ComprasCreate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'compras',
        action: 'create',
        pageTitle: 'Crear OC',
        breadcrumb: [
          {
            text: 'OC',
            to: '/compras',
          },
          {
            text: 'Crear OC',
            active: true,
          },
        ],
      },
    },
    {
      path: '/compras/:id/editar',
      name: 'compras-update',
      component: () => import('@/views/compras/ComprasUpdate.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        if (store.getters['auth/authenticated']) {
          const abilitys = store.getters['auth/authenticated'].ability
          const actions = abilitys.filter(ab => ab.subject === to.meta.subject)
          const permission = actions.find(ab => ab.action === to.meta.action)

          if (typeof permission === 'undefined') {
            return next({
              name: 'home',
            })
          }
        }
        return next()
      },
      meta: {
        subject: 'compras',
        action: 'update',
        pageTitle: 'Editar Compra',
        breadcrumb: [
          {
            text: 'Compras',
            to: '/compras',
          },
          {
            text: 'Editar Compra',
            active: true,
          },
        ],
      },
    },
    {
      path: '/configuraciones',
      name: 'configuraciones',
      component: () => import('@/views/navBar/Configuraciones.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.getters['auth/authenticated']) {
          return next({
            name: 'login',
          })
        }
        return next()
      },
      meta: {
        pageTitle: 'Configuraciones',
        breadcrumb: [
          {
            text: 'Configuraciones',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/authenticated']) {
          return next({
            name: 'home',
          })
        }
        return next()
      },
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  localStorage.setItem('userData', JSON.stringify(store.getters['auth/user']))
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
