import axios from 'axios'

export default async function ubicaciones() {
  return 0
}

export async function fetchUbicaciones({ commit }) {
  try {
    const { data } = await axios.get('ubicaciones')
    commit('setUbicaciones', data)
  } catch (e) {
    commit('ubicacionError', e.response.data)
  }
}

export async function fetchUbicacionesActivas({ commit }) {
  try {
    const { data } = await axios.get('ubicaciones/activos')
    commit('setUbicacionesActivas', data)
  } catch (e) {
    commit('ubicacionError', e.response.data)
  }
}

export async function addUbicaciones({ commit, dispatch }, ubicacion) {
  try {
    commit('ubicacionErrorNull')
    await axios({
      method: 'POST',
      url: 'ubicaciones',
      data: {
        nombre: ubicacion.nombre,
        estado: ubicacion.estado,
      },
    })
    dispatch('fetchUbicaciones')
    dispatch('fetchUbicacionesActivas')
  } catch (e) {
    commit('ubicacionError', e.response.data)
  }
}

export async function updateUbicaciones({ commit, dispatch }, ubicacion) {
  try {
    commit('ubicacionErrorNull')
    await axios({
      method: 'PUT',
      url: `/ubicaciones/${ubicacion.id}`,
      data: {
        nombre: ubicacion.nombre,
        estado: ubicacion.estado,
      },
    })
    dispatch('fetchUbicaciones')
    dispatch('fetchUbicacionesActivas')
  } catch (e) {
    commit('ubicacionError', e.response.data)
  }
}

export async function updateUbicacionEstado({ commit, dispatch }, ubicacion) {
  let est = ''
  try {
    commit('ubicacionErrorNull')
    if (ubicacion.estado === 'Inactivo') {
      est = 'Activo'
    } else if (ubicacion.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/ubicaciones/${ubicacion.id}`,
      data: {
        nombre: ubicacion.nombre,
        estado: est,
      },
    })
    // actualizamos lista de ubicacions
    dispatch('fetchUbicaciones')
    dispatch('fetchUbicacionesActivas')
  } catch (e) {
    commit('ubicacionError', e.response.data)
  }
}

export async function removeUbicaciones({ commit, dispatch }, id) {
  try {
    await axios({
      method: 'DELETE',
      url: `/ubicaciones/${id}`,
    })
    // actualizamos lista de ubicaciones
    dispatch('fetchUbicaciones')
  } catch (e) {
    commit('ubicacionError', e.response.data)
  }
}
