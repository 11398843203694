export function setAlarmas(state, alarmas) {
  state.alarmas = alarmas
}

export function alarmaError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.alarmas = []
}

export function alarmaErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
