export function setCategorias(state, categorias) {
  state.categorias = categorias
}

export function setCategoriasActivas(state, categorias) {
  state.categoriasActivas = categorias
}

export function setCategoria(state, categoria) {
  state.selectedCategoria = categoria
}

export function updateCategoriaEstado(state, payload) {
  const categoria = state.categorias.find(u => u.id === payload.id)
  if (categoria) {
    state.done = !categoria.done
  }
}

export function categoriaError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.categorias = []
}

export function categoriaErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
