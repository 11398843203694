export default function producto() {
  return 0
}

export function getProductos(state) {
  return state.productos
}

export function getProductosHistorial(state) {
  return state.productosHistorial
}

export function getProductosSalidas(state) {
  return state.productosSalidas
}

export function getProductosCompras(state) {
  return state.productosCompras
}

export function getUnidades(state) {
  return state.unidades
}
