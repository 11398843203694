export function setCompras(state, compras) {
  state.compras = compras
}

export function setCompra(state, compra) {
  state.selectedCompra = compra
}

export function updateCompraEstado(state, payload) {
  const compra = state.compras.find(u => u.id === payload.id)
  if (compra) {
    state.done = !compra.done
  }
}

export function compraError(state, payload) {
  state.error = true
  state.errorMessage = payload
  // state.compras = []
}

export function compraErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
