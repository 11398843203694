export function setClientes(state, clientes) {
  state.clientes = clientes
}

export function setClientesActivos(state, clientesActivos) {
  state.clientesActivos = clientesActivos
}

export function setCliente(state, cliente) {
  state.selectedCliente = cliente
}

export function updateClienteEstado(state, payload) {
  const cliente = state.clientes.find(u => u.id === payload.id)
  if (cliente) {
    state.done = !cliente.done
  }
}

export function clienteError(state, payload) {
  state.error = true
  state.errorMessage = payload
  state.clientes = []
}

export function clienteErrorNull(state) {
  state.error = false
  state.errorMessage = []
}
