export default function ubicacion() {
  return 0
}

export function getUbicaciones(state) {
  return state.ubicaciones
}

export function getUbicacionesActivas(state) {
  return state.ubicacionesActivas
}
