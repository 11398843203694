import axios from 'axios'

export default async function defaultProveedores() {
  return 0
}

export async function fetchProveedores({ commit }) {
  try {
    const { data } = await axios.get('proveedores')
    commit('setProveedores', data)
  } catch (e) {
    commit('proveedorError', e.response.data)
  }
}

export async function fetchProveedoresActivos({ commit }) {
  try {
    const { data } = await axios.get('proveedores/activos')
    commit('setProveedoresActivos', data)
  } catch (e) {
    commit('proveedorError', e.response.data)
  }
}

export async function addProveedores({ commit, dispatch }, proveedor) {
  try {
    commit('proveedorErrorNull')
    await axios({
      method: 'POST',
      url: 'proveedores',
      data: {
        rut: proveedor.rut,
        direccion: proveedor.direccion,
        razonSocial: proveedor.razonSocial,
        nombre: proveedor.nombre,
        giro: proveedor.giro,
        telefono: proveedor.telefono,
        correo: proveedor.correo,
        estado: 'Activo',
      },
    })
    dispatch('fetchProveedores')
  } catch (e) {
    commit('proveedorError', e.response.data)
  }
}

export async function updateProveedores({ commit, dispatch }, proveedor) {
  try {
    commit('proveedorErrorNull')
    await axios({
      method: 'PUT',
      url: `/proveedores/${proveedor.id}`,
      data: {
        rut: proveedor.rut,
        direccion: proveedor.direccion,
        razonSocial: proveedor.razonSocial,
        nombre: proveedor.nombre,
        giro: proveedor.giro,
        telefono: proveedor.telefono,
        correo: proveedor.correo,
        estado: 'Activo',
      },
    })
    dispatch('fetchProveedores')
  } catch (e) {
    commit('proveedorError', e.response.data)
  }
}

export async function updateProveedorEstado({ commit, dispatch }, proveedor) {
  let est = ''
  try {
    commit('proveedorErrorNull')
    if (proveedor.estado === 'Inactivo') {
      est = 'Activo'
    } else if (proveedor.estado === 'Activo') {
      est = 'Inactivo'
    }
    await axios({
      method: 'PUT',
      url: `/proveedores/${proveedor.id}`,
      data: {
        rut: proveedor.rut,
        direccion: proveedor.direccion,
        razonSocial: proveedor.razonSocial,
        nombre: proveedor.nombre,
        giro: proveedor.giro,
        telefono: proveedor.telefono,
        correo: proveedor.correo,
        estado: est,
      },
    })
    // actualizamos lista de proveedores
    dispatch('fetchProveedores')
  } catch (e) {
    commit('proveedorError', e.response.data)
  }
}

export async function removeProveedores({ commit, dispatch }, id) {
  try {
    commit('proveedorErrorNull')
    await axios({
      method: 'DELETE',
      url: `/proveedores/${id}`,
    })
    // actualizamos lista de proveedores
    dispatch('fetchProveedores')
  } catch (e) {
    commit('proveedorError', e.response.data)
  }
}

export async function importProveedores({ commit }, proveedores) {
  try {
    await axios({
      method: 'POST',
      url: 'proveedores/import',
      data: {
        proveedores,
      },
    })
  } catch (e) {
    commit('clienteError', e.response.data)
  }
}
