import axios from 'axios'

export default async function permission() {
  return 0
}

export async function fetchPermissions({ commit }) {
  try {
    const { data } = await axios.get('permissions')
    commit('setPermissions', data)
  } catch (e) {
    commit('permissionError', e.response.data)
  }
}
