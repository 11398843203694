export default function categoria() {
  return 0
}

export function getCategorias(state) {
  return state.categorias
}

export function getCategoriasActivas(state) {
  return state.categoriasActivas
}
