export default function proveedor() {
  return 0
}

export function getProveedores(state) {
  return state.proveedores
}

export function getProveedoresActivos(state) {
  return state.proveedoresActivos
}
